export enum DeleteDeviceActionTypes {
  DELETE_DEVICES_REQUEST = 'DELETE_DEVICES_REQUEST',
  DELETE_DEVICES_SUCCESS = 'DELETE_DEVICES_SUCCESS',
  DELETE_DEVICES_ERROR = 'DELETE_DEVICES_ERROR',
}

export interface DeletDeviceRequestAction {
  type: DeleteDeviceActionTypes.DELETE_DEVICES_REQUEST;
  id: string;
}
export interface DeleteDeviceSuccessAction {
  type: DeleteDeviceActionTypes.DELETE_DEVICES_SUCCESS;
}

export interface DeleteDeviceErrorAction {
  type: DeleteDeviceActionTypes.DELETE_DEVICES_ERROR;
  error: Error;
}

export type DeletDeviceAction = DeletDeviceRequestAction | DeleteDeviceSuccessAction | DeleteDeviceErrorAction;

export function deleteDeviceRequest( id: string ): DeletDeviceRequestAction {
  return {
    type: DeleteDeviceActionTypes.DELETE_DEVICES_REQUEST,
    id,
  };
}

export function deleteDeviceSuccess(): DeleteDeviceSuccessAction {
  return {
    type: DeleteDeviceActionTypes.DELETE_DEVICES_SUCCESS,
  };
}

export function deleteDeviceError( error: Error ): DeleteDeviceErrorAction {
  return {
    type: DeleteDeviceActionTypes.DELETE_DEVICES_ERROR,
    error,
  };
}
