import { Observable, filter, switchMap } from 'rxjs';
import { setAnonymousClient, setAuthenticatedClient } from '../../../../client/graphql';

import { DeletDeviceAction, DeletDeviceRequestAction, DeleteDeviceActionTypes, deleteDeviceError, deleteDeviceRequest, deleteDeviceSuccess } from './action';
import { deleteDeviceMutation } from './query';

export function deleteDeviceEpic( action$ ): Observable<DeletDeviceAction> {
  return action$.pipe(
    filter( ( action: DeletDeviceAction ) => action.type === DeleteDeviceActionTypes.DELETE_DEVICES_REQUEST ),
    switchMap( ( { id }: DeletDeviceRequestAction ) =>
      deleteDevice( id ).then( deleteDeviceSuccess ).catch( deleteDeviceError ),
    ),
  );
}

export async function deleteDevice( input ) {
  const graphQLClient = await setAuthenticatedClient();

  const { data: { deleteDevice: device } } = await graphQLClient.mutate( {
    mutation: deleteDeviceMutation,
    variables: { input: { id: input } },
  } );

  return device;
}
