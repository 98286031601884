import { Device } from "../../types"

export enum SearchDeviceActionTypes {
    SEARCH_DEVICES_REQUEST = 'SEARCH_DEVICES_REQUEST',
    SEARCH_DEVICES_SUCCESS = 'SEARCH_DEVICES_SUCCESS',
    SEARCH_DEVICES_ERROR = 'SEARCH_DEVICES_ERROR',
    ZOOM_TO_DEVICE = "ZOOM_TO_DEVICE"
}

export interface ZoomToDeviceAction {
    type: SearchDeviceActionTypes.ZOOM_TO_DEVICE
    payload: any
}

export interface SearchDeviceRequestAction {
    type: SearchDeviceActionTypes.SEARCH_DEVICES_REQUEST
    searchQuery: string
}

export interface SearchDeviceSuccessAction {
    type: SearchDeviceActionTypes.SEARCH_DEVICES_SUCCESS
    devices: Device[]
}

export interface SearchDeviceErrorAction {
    type: SearchDeviceActionTypes.SEARCH_DEVICES_ERROR
    error: Error
}

export type SearchDeviceActions =
    | SearchDeviceRequestAction
    | SearchDeviceSuccessAction
    | SearchDeviceErrorAction
    | ZoomToDeviceAction

export function searchDeviceRequest(searchQuery: string): SearchDeviceRequestAction {
    return {
        type: SearchDeviceActionTypes.SEARCH_DEVICES_REQUEST,
        searchQuery
    }
}

export function searchDeviceError(error: Error): SearchDeviceErrorAction {
    return {
        type: SearchDeviceActionTypes.SEARCH_DEVICES_ERROR,
        error,
    }
}

export function searchDeviceSuccess(devices: Device[]): SearchDeviceSuccessAction {
    return {
        type: SearchDeviceActionTypes.SEARCH_DEVICES_SUCCESS,
        devices,
    }
}

export function zoomToDevice(payload: any): ZoomToDeviceAction {
    return {
        type: SearchDeviceActionTypes.ZOOM_TO_DEVICE,
        payload
    }
};