import update from 'immutability-helper';
import { ListUsersAction, ListUsersActionTypes } from './action';

export interface ListUsersState {
  loading: boolean;
  error: null | Error;
  usersData: any

}

export const initialListDeviceStatus: ListUsersState = {
  loading: false,
  error: null,
  usersData: null
};

export default function listUsersReducer( state = initialListDeviceStatus, action: ListUsersAction ) {
  switch ( action.type ) {
    case ListUsersActionTypes.LIST_USERS_REQUEST:
      return update( state, {
        loading: { $set: true },
      } );
    case ListUsersActionTypes.LIST_USERS_SUCCESS:
      return update( state, {
        loading: { $set: false },
        usersData: { $set: action.users }
      } );
    case ListUsersActionTypes.LIST_USERS_ERROR:
      return update( state, {
        loading: { $set: false },
        error: { $set: action.error },
      } );
    default:
      return state;
  }
}
