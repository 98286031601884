import update from 'immutability-helper';
import { GetUserActionTypes, GetUserActions } from './action';

export interface GetUserState {
    loading: boolean;
    error: null | Error;
    userData: unknown;
    openPanel: boolean;
}

export const initialGetUserStatus: GetUserState = {
    loading: false,
    error: null,
    userData: null,
    openPanel: false,
};

export default function getUserReducer( state = initialGetUserStatus, action: GetUserActions ) {
    switch ( action.type ) {
        case GetUserActionTypes.GET_USER_REQUEST:
            return update( state, {
                loading: { $set: true },
            } );
        case GetUserActionTypes.GET_USER_SUCCESS:
            return update( state, {
                loading: { $set: false },
                userData: { $set: action.payload },
            } );
        case GetUserActionTypes.GET_USER_ERROR:
            return update( state, {
                loading: { $set: false },
                error: { $set: action.error },
            } );
        default:
            return state;
    }
}
