import { gql } from "@apollo/client";

export const approveDeviceMutation = gql`
mutation approveDevice($input: ApproveDeviceInput!) {
  approveDevice(input: $input) {
    ... on Terminal {
      id
      __typename
      address
      approvalsCount
      notes
      registrationDate
      status
      location {
        lat
        lon
      }
      user {
        id
      }
    }
  }
}
`;
