/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { AuthActions, EnterCredentials, LoginStage, ModalAction, Navigation, StageAction, UserLocations, initialClientActions } from './action';

export interface ModalState {
  isOpen: boolean;
  stageActive: StageAction;
  email: string;
  emailError: string;
  password: string;
  newPassword: string;
  smsCode: string;
  passwordError: string;
  error: Error;
  user: boolean;
  loginSuccess: any;
  stageHistory: StageAction[];
  currentUser?: any;
  currentUserSession?: any;
  client?: ApolloClient<NormalizedCacheObject>;
  initAuth?: any;
  session?: any;
  isLoading?: boolean;
  loading: boolean;
  id: '';
  lat: number;
  lon: number
}
export const initialModalState: ModalState = {
  user: false,
  isOpen: false,
  stageActive: StageAction.LOGIN_CHOOSE_OPTION,
  email: '',
  emailError: '',
  password: '',
  newPassword: '',
  passwordError: '',
  smsCode: '',
  loading: false,
  loginSuccess: null,
  isLoading: false,
  id: '',
  error: null,
  stageHistory: [StageAction.LOGIN_CHOOSE_OPTION],
  lat: 0,
  lon: 0
};

export const validRegexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const validRegexPassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

export const authReducer = (state = initialModalState, action: AuthActions): ModalState => {
  switch (action.type) {
    case ModalAction.OPEN_MODAL:
      return update(state, {
        isOpen: { $set: true },
      });
    case ModalAction.CLOSE_MODAL:
      return update(state, {
        isOpen: { $set: false },
      });
    case StageAction.SET_STAGE:
      return update(state, {
        stageActive: { $set: action.payload },
        error: { $set: null }
      });
    case LoginStage.RESET_PASSWORD_SUCCESS:
      return update(state, {
        stageActive: { $set: StageAction.SIGN_IN_ENTER_CREDENTIALS },
      });
    case LoginStage.VERIFY_OTP_ERROR:
      return update(state, {
        stageActive: { $set: StageAction.LOGIN_VERIFY_SMS_CODE },
        error: { $set: action.error },
        isLoading: { $set: false },
      });
    case LoginStage.FORGOT_PASSWORD_SUCCESS:
      return update(state, {
        stageActive: { $set: StageAction.SIGN_IN_VERIFY_RESET_CODE },
      });
    case LoginStage.LOGIN_REQUEST:
      return update(state, {
        isLoading: { $set: true }
      })
    case LoginStage.LOGIN_IN_SUCCESS:
      const { result } = action;
      return update(state, {
        isOpen: { $set: false },
        user: { $set: true },
        loginSuccess: { $set: result },
        isLoading: { $set: false }
      });
    case LoginStage.LOGIN_IN_ERROR:
      return update(state, {
        error: { $set: action.error },
        isLoading: { $set: false }
      });
    case LoginStage.ENTER_OTP:
      return update(state, {
        isLoading: { $set: true },
        smsCode: { $set: action.smsCode },
      });
    case EnterCredentials.LOGIN_SET_EMAIL:
      const { payload } = action;

      if (payload && payload.match(validRegexEmail)) {
        return update(state, {
          email: { $set: payload },
          emailError: { $set: '' },
        });
      } else {
        return update(state, {
          email: { $set: payload },
          emailError: { $set: 'Некорректный адрес электронной почты' },
        });
      }
    case EnterCredentials.LOGIN_SET_PASSWORD:
      const { password } = action;
      if (password.match(validRegexPassword)) {
        return update(state, {
          password: { $set: password },
          passwordError: { $set: '' },
        });
      } else {
        return update(state, {
          password: { $set: password },
          passwordError: { $set: 'Неверный пароль' },
        });
      }
    case EnterCredentials.LOGIN_SET_OTP:
      return update(state, {
        smsCode: { $set: action.payload },
      });
    case EnterCredentials.LOGIN_SET_NEW_PASSWORD:
      return update(state, {
        newPassword: { $set: action.payload },
      });
    case LoginStage.ENTER_CREDENTIALS:
      return update(state, {
        isLoading: { $set: true }
      })
    case LoginStage.ENTER_CREDENTIALS_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        stageActive: { $set: StageAction.LOGIN_VERIFY_SMS_CODE },
      });
    case LoginStage.VERIFY_OTP_SUCCESS:
      return update(state, {
        stageActive: { $set: StageAction.SIGN_IN_ENTER_CREDENTIALS },
        smsCode: { $set: '' },
        email: { $set: null },
        password: { $set: null },
        isLoading: { $set: false }
      });
    case LoginStage.ENTER_CREDENTIALS_ERROR:
      return update(state, {
        isLoading: { $set: false },
        stageActive: { $set: StageAction.LOGIN_ENTER_CREDENTIALS },
        error: { $set: action.error },
      });
    case Navigation.BACK:
      const previoustage = state.stageHistory[state.stageHistory.length - 1];
      const updatedHistory = state.stageHistory.slice(0, -1);
      return update(state, {
        stageActive: { $set: previoustage },
        stageHistory: { $push: updatedHistory },
      });
    case initialClientActions.INIT_CLIENT_REQUEST:
      return update(state, {
        user: { $set: false },
        loading: { $set: true },
      });
    case initialClientActions.INIT_CLIENT_SUCCESS:
      const emailAttribute = action.session.find(attr => attr.Name === 'email');
      const emailValue = emailAttribute ? emailAttribute.Value : null;
      const valueAttribute = action.session.find(attr => attr.Name === 'sub');
      const value = valueAttribute ? valueAttribute.Value : null;

      return update(state, {
        session: { $set: action.session },
        email: { $set: emailValue },
        user: { $set: true },
        loading: { $set: false },
        id: { $set: value },
      });

    case initialClientActions.INIT_CLIENT_ERROR:
      return update(state, {
        user: { $set: false },
        loading: { $set: false },
        error: { $set: action.error }
      });
    case UserLocations.SET_LAT:
      return update(state, {
        lat: { $set: action.payload }
      })
    case UserLocations.SET_LON:
      return update(state, {
        lon: { $set: action.payload }
      })
    default:
      return state;
  }
};

export default authReducer;
