import update from 'immutability-helper';
import { WebAppActions, WebAppActionsTypes } from "./action";

export interface WebAppState {
  openLaderBoard: boolean,
  currentMarker: unknown,
  logoutOpenModal: boolean
}

export const initialWebAppStatus: WebAppState = {
  openLaderBoard: false,
  currentMarker: null,
  logoutOpenModal: false,
};


export default function webAppReducer(state = initialWebAppStatus, action: WebAppActions) {
  switch (action.type) {
    case WebAppActionsTypes.OPEN_LADER_BOARD:
      return update(state, {
        openLaderBoard: { $set: true }
      })
    case WebAppActionsTypes.CLOSE_LADER_BOARD:
      return update(state, {
        openLaderBoard: { $set: false }
      })
    case WebAppActionsTypes.SET_CURRENT_MARKER:
      return update(state, {
        currentMarker: { $set: action.payload }
      })
    case WebAppActionsTypes.OPEN_USER_LOGOUT:
      return update(state, {
        logoutOpenModal: { $set: true }
      })
    case WebAppActionsTypes.CLOSE_USER_LOGOUT:
      return update(state, {
        logoutOpenModal: { $set: false }
      })
    default:
      return state
  }

}
