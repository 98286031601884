// External dependencies
import { gql } from "@apollo/client";

export const getDeviceMutation = gql`
query GetDevice($input: GetDeviceInput!){
  getDevice(input: $input)  {
    ... on Terminal {
          id
          __typename
          address
          approvalsCount
          location {
            lat
            lon
          }
          notes
          organization
          registrationDate
          status
          user {
            id
          }
        }
  }
}
`;