import update from 'immutability-helper';
import { DeletDeviceAction, DeleteDeviceActionTypes } from './action';

export interface DeleteDeviceState {
  loading: boolean;
  error: null | Error;
}

export const initialCreateDeviceStatus: DeleteDeviceState = {
  loading: false,
  error: null,
};

export default function deleteDevice( state = initialCreateDeviceStatus, action: DeletDeviceAction ) {
  switch ( action.type ) {
    case DeleteDeviceActionTypes.DELETE_DEVICES_REQUEST:
      return update( state, {
        loading: { $set: true },
      } );
    case DeleteDeviceActionTypes.DELETE_DEVICES_SUCCESS:
      return update( state, {
        loading: { $set: false },
      } );
    case DeleteDeviceActionTypes.DELETE_DEVICES_ERROR:
      return update( state, {
        loading: { $set: false },
        error: { $set: action.error },
      } );
    default:
      return state;
  }
}
