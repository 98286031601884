export enum WebAppActionsTypes {
  OPEN_LADER_BOARD = 'OPEN_LADER_BOARD',
  CLOSE_LADER_BOARD = 'CLOSE_LADER_BOARD',
  SET_CURRENT_MARKER = 'SET_CURRENT_MARKER',
  OPEN_USER_LOGOUT = 'OPEN_USER_LOGOUT',
  CLOSE_USER_LOGOUT = 'CLOSE_USER_LOGOUT',
  OPEN_SIDE_DRAWER = 'OPEN_SIDE_DRAWER',
  CLOSE_SIDE_DRAWER = 'CLOSE_SIDE_DRAWER',
}

export interface OpenLaderBoardActions {
  type: WebAppActionsTypes.OPEN_LADER_BOARD
}

export interface CloseLaderBoardActions {
  type: WebAppActionsTypes.CLOSE_LADER_BOARD
}

export interface OpenSideDrawerActions {
  type: WebAppActionsTypes.OPEN_SIDE_DRAWER
}

export interface CloseSideDrawerActions {
  type: WebAppActionsTypes.CLOSE_SIDE_DRAWER
}

export interface SetCurrentMarker {
  type: WebAppActionsTypes.SET_CURRENT_MARKER
  payload: any
}

export interface CloseUserLogoutActions {
  type: WebAppActionsTypes.CLOSE_USER_LOGOUT
}

export interface OpenUserLogoutActions {
  type: WebAppActionsTypes.OPEN_USER_LOGOUT
}

export type WebAppActions = OpenLaderBoardActions | CloseLaderBoardActions | SetCurrentMarker | CloseUserLogoutActions | OpenUserLogoutActions | OpenSideDrawerActions | CloseSideDrawerActions

export const openLaderBoard = (): OpenLaderBoardActions => ({
  type: WebAppActionsTypes.OPEN_LADER_BOARD
});


export const closeLaderBoard = (): CloseLaderBoardActions => ({
  type: WebAppActionsTypes.CLOSE_LADER_BOARD
});

export const openUserLogout = (): OpenUserLogoutActions => ({
  type: WebAppActionsTypes.OPEN_USER_LOGOUT
});


export const closeUserLogout = (): CloseUserLogoutActions => ({
  type: WebAppActionsTypes.CLOSE_USER_LOGOUT
});

export const setCurrentMarker = (payload: any): SetCurrentMarker => ({
  type: WebAppActionsTypes.SET_CURRENT_MARKER,
  payload
});
