import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { approveDeviceMutation } from './query';
import { ApproveDeviceActionTypes, ApproveDeviceActions, ApproveDeviceRequestAction, approveDeviceError, approveDeviceSuccess } from './action';

export function approveDeviceEpic( action$: Observable<ApproveDeviceActions> ): Observable<ApproveDeviceActions> {
    return action$.pipe(
        filter( ( action: ApproveDeviceActions ) => action.type === ApproveDeviceActionTypes.APPROVE_DEVICE_REQUEST ),
        switchMap( ( { id, lat, lon }: ApproveDeviceRequestAction ) =>
            approveDevice( id, lat, lon ).then( approveDeviceSuccess ).catch( approveDeviceError )
        )
    );
}

async function approveDevice( id: string, lat: number, lon: number ) {
    const graphQLClient = await setAuthenticatedClient();

    const { data: { approveDevice: device } } = await graphQLClient.mutate( {
        mutation: approveDeviceMutation,
        variables: { input: { id, location: { lat, lon } } }
    } );

    return device;
}
