/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import {
  confirmPasswordInForgotPasswordEpic,
  forgotPasswordEpic,
  initialUserEpic,
  loginEpic,
  signUpConfirmCodeEpic,
  signUpEpic,
} from '../components/webApp/shared/ui/modal/redux/epics';
import getUserReducer from '../features/users/getUser/redux/reducer';
import { getUserEpic } from '../features/users/getUser/redux/epic';
import webAppReducer from '../components/webApp/redux/reducer';
import listUsersReducer from '../features/users/listUsers/redux/reducer';
import { listUsersEpic } from '../features/users/listUsers/redux/epic';
import { deleteDeviceEpic } from '../features/devices/deleteDevice/redux/epic';
import { listDeviceEpic } from '../features/devices/listDevices/redux/epic';
import { createDeviceEpic } from '../features/devices/createDevice/redux/epic';
import { getDeviceEpic } from '../features/devices/getDevice/redux/epic';
import listDevice from '../features/devices/listDevices/redux/reducer';
import createDeviceReducer from '../features/devices/createDevice/redux/reducer';
import deleteDevice from '../features/devices/deleteDevice/redux/reducer';
import getDeviceReducer from '../features/devices/getDevice/redux/reducer';
import updateUsersReducer from '../features/users/updateUser/redux/reducer';
import { updateUserEpic } from '../features/users/updateUser/redux/epic';
import approveDeviceReducer from '../features/devices/approveDevice/redux/reducer';
import { approveDeviceEpic } from '../features/devices/approveDevice/redux/epic';
import authReducer from '../components/webApp/shared/ui/modal/redux/reducer';
import updateDeviceReducer from '../features/devices/updateDevice/redux/reducer';
import { updateDeviceEpic } from '../features/devices/updateDevice/redux/epic';
import searchDeviceReducer from '../features/devices/searchDevice/redux/reducer';
import { searchDeviceEpic } from '../features/devices/searchDevice/redux/epic';

const rootEpic = combineEpics(
  deleteDeviceEpic,
  signUpEpic,
  signUpConfirmCodeEpic,
  loginEpic,
  forgotPasswordEpic,
  confirmPasswordInForgotPasswordEpic,
  listDeviceEpic,
  createDeviceEpic,
  getDeviceEpic,
  getUserEpic,
  initialUserEpic,
  listUsersEpic,
  updateUserEpic,
  approveDeviceEpic,
  updateDeviceEpic,
  searchDeviceEpic
);

const rootReducer = combineReducers( {
  authReducer,
  listDevice,
  createDeviceReducer,
  deleteDevice,
  getDeviceReducer,
  getUserReducer,
  webAppReducer,
  listUsersReducer,
  updateUsersReducer,
  approveDeviceReducer,
  updateDeviceReducer,
  searchDeviceReducer
} );

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore( rootReducer, composeWithDevTools( applyMiddleware( epicMiddleware ) ) );

  epicMiddleware.run( rootEpic );

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
