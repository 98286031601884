export enum ApproveDeviceActionTypes {
    APPROVE_DEVICE_REQUEST = 'APPROVE_DEVICE_REQUEST',
    APPROVE_DEVICE_SUCCESS = 'APPROVE_DEVICE_SUCCESS',
    APPROVE_DEVICE_ERROR = 'APPROVE_DEVICE_ERROR',
    APPROVE_DEVICE_ERROR_CLEAR = 'APPROVE_DEVICE_ERROR_CLEAR',
    SET_SUCCESS_NOTIFICATION = 'SET_SUCCESS_NOTIFICATION'
}

export interface ApproveDeviceRequestAction {
    type: ApproveDeviceActionTypes.APPROVE_DEVICE_REQUEST
    id: string,
    lat: number,
    lon: number
}

export interface ApproveDeviceErrorClearAction {
    type: ApproveDeviceActionTypes.APPROVE_DEVICE_ERROR_CLEAR
}

export interface ApproveDeviceSuccessAction {
    type: ApproveDeviceActionTypes.APPROVE_DEVICE_SUCCESS
    payload: any
}
export interface SuccessNotificationAction {
    type: ApproveDeviceActionTypes.SET_SUCCESS_NOTIFICATION,
    payload: boolean
}


export interface ApproveDeviceErrorAction {
    type: ApproveDeviceActionTypes.APPROVE_DEVICE_ERROR
    error: Error
}

export type ApproveDeviceActions = ApproveDeviceRequestAction | ApproveDeviceSuccessAction | ApproveDeviceErrorAction | SuccessNotificationAction | ApproveDeviceErrorClearAction


export function approveDeviceRequest(id: string, lat: number, lon: number): ApproveDeviceRequestAction {
    return { type: ApproveDeviceActionTypes.APPROVE_DEVICE_REQUEST, id, lat, lon };
}

export function approveDeviceSuccess(payload: any): ApproveDeviceSuccessAction {
    return { type: ApproveDeviceActionTypes.APPROVE_DEVICE_SUCCESS, payload };
}

export function approveDeviceError(error: Error): ApproveDeviceErrorAction {
    return { type: ApproveDeviceActionTypes.APPROVE_DEVICE_ERROR, error };
}

export function setSuccessNotification(payload: boolean): SuccessNotificationAction {
    return {
        type: ApproveDeviceActionTypes.SET_SUCCESS_NOTIFICATION,
        payload
    };
}

export function setClearErrorApproveDevice(): ApproveDeviceErrorClearAction {
    return {
        type: ApproveDeviceActionTypes.APPROVE_DEVICE_ERROR_CLEAR
    }
}
