/* External dependencies */
import CognitoClient from '@mancho.devs/cognito';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getSession } from '../../../../../../client/graphql';
import {
  AuthActions,
  ConfirmSignupCodeAction,
  EnterCredentialsAction,
  ForgotPasswordRequestAction,
  LoginAction,
  LoginStage,
  ResetPasswordRequestAction,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  initialClientActions,
  initialError,
  initialSuccess,
  loginError,
  loginSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  userSignUpFailure,
  userSignUpSuccess,
  verifyOtpError,
  verifyOtpSuccess,
} from './action';

const cognitoClient = new CognitoClient( {
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
} );

export function signUpEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === LoginStage.ENTER_CREDENTIALS ),
    switchMap( ( { email, password }: EnterCredentialsAction ) =>
      cognitoClient.signUp( email, password ).then( userSignUpSuccess ).catch( userSignUpFailure ),
    ),
  );
}

export function signUpConfirmCodeEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === LoginStage.ENTER_OTP ),
    switchMap( ( { email, smsCode }: ConfirmSignupCodeAction ) =>
      cognitoClient.signUpConfirmCode( email, smsCode ).then( verifyOtpSuccess ).catch( verifyOtpError ),
    ),
  );
}

export function loginEpic(action$: Observable<AuthActions>): Observable<AuthActions> {
  return action$.pipe(
    filter((action: AuthActions) => action.type === LoginStage.LOGIN_REQUEST),
    mergeMap((action: LoginAction) =>
      from(cognitoClient.signIn(action.email, action.password)).pipe(
        mergeMap((signInResponse) =>
          from(getSession()).pipe(
            mergeMap((session) => {
              return from([
                loginSuccess(signInResponse),
                initialSuccess(session),
              ]);
            }),
            catchError((sessionError) => {
              return of(loginSuccess(signInResponse), initialError(sessionError));
            })
          )
        ),
        catchError((signInError) => {
          return of(loginError(signInError));
        })
      )
    )
  );
}

export function forgotPasswordEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === LoginStage.FORGOT_PASSWORD_REQUEST ),
    switchMap( ( { email }: ForgotPasswordRequestAction ) =>
      cognitoClient.forgotPassword( email ).then( forgotPasswordSuccess ).catch( forgotPasswordFailure ),
    ),
  );
}

export function confirmPasswordInForgotPasswordEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === LoginStage.RESET_PASSWORD_REQUEST ),
    switchMap( ( { email, smsCode, password }: ResetPasswordRequestAction ) =>
      cognitoClient.confirmPassword( email, smsCode, password ).then( resetPasswordSuccess ).catch( resetPasswordFailure ),
    ),
  );
}

export function initialUserEpic( action$ ): Observable<AuthActions> {
  return action$.pipe(
    filter( ( action: AuthActions ) => action.type === initialClientActions.INIT_CLIENT_REQUEST ),
    switchMap( ( { } ) => getSession().then( initialSuccess ).catch( initialError ) ),
  );
}
