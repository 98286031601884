import update from 'immutability-helper';
import { Device } from '../../types';
import { ListDeviceAction, ListDeviceActionTypes } from './action';

export interface ListDeviceState {
  loading: boolean;
  loadingSearch: boolean;
  error: null | Error;
  devices: any;
  total: number;
  isOpen: boolean;
  isMarkerPlaced: boolean;
  activeMarker: any
}

export const initialListDeviceStatus: ListDeviceState = {
  loading: false,
  loadingSearch: false,
  error: null,
  devices: [],
  total: 0,
  isOpen: false,
  isMarkerPlaced: false,
  activeMarker: null
};

export default function listDevice(state = initialListDeviceStatus, action: ListDeviceAction) {
  switch (action.type) {
    case ListDeviceActionTypes.LIST_DEVICES_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case ListDeviceActionTypes.LIST_DEVICES_SUCCESS:
      if (action.filter && action.filter.devices) {
        return update(state, {
          loading: { $set: false },
          devices: { $set: action.filter.devices },
          total: { $set: action.filter.total },
        });
      } else {
        return state;
      }
    case ListDeviceActionTypes.LIST_DEVICES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case ListDeviceActionTypes.SET_MARKER_PLACED:
      return update(state, {
        isMarkerPlaced: { $set: action.payload },
      });
    case ListDeviceActionTypes.SET_ACTIVE_MARKER:
      return update(state, {
        activeMarker: { $set: action.payload }
      })
    default:
      return state;
  }
}
