export enum ModalAction {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export enum StageAction {
  LOGIN_CHOOSE_OPTION = 'LOGIN_CHOOSE_OPTION',
  LOGIN_ENTER_CREDENTIALS = 'LOGIN_ENTER_CREDENTIALS',
  LOGIN_VERIFY_SMS_CODE = 'LOGIN_VERIFY_SMS_CODE',
  SIGN_IN_ENTER_CREDENTIALS = 'SIGN_IN_ENTER_CREDENTIALS',
  SIGN_IN_FORGOT_PASSWORD = 'SIGN_IN_FORGOT_PASSWORD',
  SIGN_IN_ENTER_EMAIL_FORGOT = 'SIGN_IN_ENTER_EMAIL_FORGOT',
  SIGN_IN_VERIFY_RESET_CODE = 'SIGN_IN_VERIFY_RESET_CODE',
  SIGN_IN_RESET_PASSWORD = 'SIGN_IN_RESET_PASSWORD',
  LOADING = 'LOADING',
  SET_STAGE = 'SET_STAGE',
}

export enum LoginStage {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_IN_SUCCESS = 'LOGIN_IN_SUCCESS',
  LOGIN_IN_ERROR = 'LOGIN_IN_ERROR',
  ENTER_OTP = 'ENTER_OTP',
  VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR',
  ENTER_CREDENTIALS = 'ENTER_CREDENTIALS',
  ENTER_CREDENTIALS_SUCCESS = 'ENTER_CREDENTIALS_SUCCESS',
  ENTER_CREDENTIALS_ERROR = 'ENTER_CREDENTIALS_ERROR',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',
  LOGIN = "LOGIN",
}

export enum EnterCredentials {
  LOGIN_SET_EMAIL = 'LOGIN_SET_EMAIL',
  LOGIN_SET_PASSWORD = 'LOGIN_SET_PASSWORD',
  LOGIN_SET_NEW_PASSWORD = 'LOGIN_SET_NEW_PASSWORD',
  LOGIN_SET_OTP = 'LOGIN_SET_OTP',
}

export enum Navigation {
  NEXT = 'NEXT',
  BACK = 'BACK',
}

export enum initialClientActions {
  INIT_CLIENT_REQUEST = 'INIT_CLIENT_REQUEST',
  INIT_CLIENT_SUCCESS = 'INIT_CLIENT_SUCCESS',
  INIT_CLIENT_ERROR = 'INIT_CLIENT_ERROR',
}


export enum UserLocations {
  SET_LAT = 'SET_LAT',
  SET_LON = 'SET_LON'
}

export interface SetLatAction {
  type: UserLocations.SET_LAT;
  payload: number;
}

export interface SetLonAction {
  type: UserLocations.SET_LON;
  payload: number;
}


export interface InitialRequestAction {
  type: initialClientActions.INIT_CLIENT_REQUEST;
}

export interface InitialSuccessAction {
  type: initialClientActions.INIT_CLIENT_SUCCESS;
  session: any;
}

export interface InitialErrorAction {
  type: initialClientActions.INIT_CLIENT_ERROR;
  error: Error;
}

export const setLat = ( payload: number ): SetLatAction => ( {
  type: UserLocations.SET_LAT,
  payload
} )

export const setLon = ( payload: number ): SetLonAction => ( {
  type: UserLocations.SET_LON,
  payload
} )

export const initialClient = (): InitialRequestAction => ( {
  type: initialClientActions.INIT_CLIENT_REQUEST,
} );
export const initialSuccess = ( session: any ): InitialSuccessAction => ( {
  type: initialClientActions.INIT_CLIENT_SUCCESS,
  session,
} );

export const initialError = ( error: Error ): InitialErrorAction => ( {
  type: initialClientActions.INIT_CLIENT_ERROR,
  error,
} );


export interface NavigationNext {
  type: Navigation.NEXT;
  payload: any;
}

export interface NavigationBack {
  type: Navigation.BACK;
  payload: any;
}

export interface ResetPasswordRequestAction {
  type: LoginStage.RESET_PASSWORD_REQUEST;
  email: string;
  smsCode: string;
  password: string;
  newPassword: string;
}

export interface ResetPasswordSuccessAction {
  type: LoginStage.RESET_PASSWORD_SUCCESS;
}

export interface ResetPasswordFailureAction {
  type: LoginStage.RESET_PASSWORD_FAILURE;
  error: Error;
}

export interface ForgotPasswordFailureAction {
  type: LoginStage.FORGOT_PASSWORD_FAILURE;
  error: string;
}

export interface ForgotPasswordSuccessAction {
  type: LoginStage.FORGOT_PASSWORD_SUCCESS;
}

export interface ForgotPasswordRequestAction {
  type: LoginStage.FORGOT_PASSWORD_REQUEST;
  email: string;
}

export interface LoginAction {
  type: LoginStage.LOGIN_REQUEST;
  email: string;
  password: string;
}

export interface LoginSuccessAction {
  type: LoginStage.LOGIN_IN_SUCCESS;
  result: any;
}

export interface LoginInErrorAction {
  type: LoginStage.LOGIN_IN_ERROR;
  error: Error;
}

export interface SetOtpInputAction {
  type: EnterCredentials.LOGIN_SET_OTP;
  smsCode: string;
  payload: string;
}

export interface ConfirmSignupCodeAction {
  type: LoginStage.ENTER_OTP;
  email: string;
  smsCode: string;
}

export interface VerifyOtpSuccess {
  type: LoginStage.VERIFY_OTP_SUCCESS;
  user: any;
}

export interface VerifyOtpError {
  type: LoginStage.VERIFY_OTP_ERROR;
  error: Error;
}

export interface SignUpSuccessAction {
  type: LoginStage.ENTER_CREDENTIALS_SUCCESS;
  user: any;
}

export interface SignUpErrorAction {
  type: LoginStage.ENTER_CREDENTIALS_ERROR;
  error: Error;
}

export interface SetEmailAddressAction {
  type: EnterCredentials.LOGIN_SET_EMAIL;
  email: string;
  payload: string;
}

export interface SetPasswordAction {
  type: EnterCredentials.LOGIN_SET_PASSWORD;
  password: string;
  payload: string;
}

export interface SetNewPasswordAction {
  type: EnterCredentials.LOGIN_SET_NEW_PASSWORD;
  newPassword: string;
  payload: string;
}

export interface EnterCredentialsAction {
  type: LoginStage.ENTER_CREDENTIALS;
  email: string;
  password: string;
}

export interface openModal {
  type: ModalAction.OPEN_MODAL;
}

export interface CloseModal {
  type: ModalAction.CLOSE_MODAL;
}

export interface SetStageAction {
  type: StageAction.SET_STAGE;
  payload: StageAction;
}

export const navigateNext = ( payload: any ): NavigationNext => ( {
  type: Navigation.NEXT,
  payload,
} );

export const navigateBack = ( payload: any ): NavigationBack => ( {
  type: Navigation.BACK,
  payload,
} );

export const resetPasswordRequest = (
  email: string,
  smsCode: string,
  password: string,
  newPassword: string,
): ResetPasswordRequestAction => ( {
  type: LoginStage.RESET_PASSWORD_REQUEST,
  email,
  smsCode,
  password,
  newPassword,
} );

export const resetPasswordSuccess = (): ResetPasswordSuccessAction => {
  return {
    type: LoginStage.RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordFailure = ( error: Error ): ResetPasswordFailureAction => ( {
  type: LoginStage.RESET_PASSWORD_FAILURE,
  error,
} );

export const forgotPasswordRequest = ( email: string ): ForgotPasswordRequestAction => ( {
  type: LoginStage.FORGOT_PASSWORD_REQUEST,
  email,
} );

export const forgotPasswordSuccess = (): ForgotPasswordSuccessAction => ( {
  type: LoginStage.FORGOT_PASSWORD_SUCCESS,
} );

export const forgotPasswordFailure = ( error: string ): ForgotPasswordFailureAction => ( {
  type: LoginStage.FORGOT_PASSWORD_FAILURE,
  error,
} );

export const login = ( email: string, password: string ): LoginAction => ( {
  type: LoginStage.LOGIN_REQUEST,
  email,
  password,
} );

export const confirmSignupCode = ( email: string, smsCode: string ): ConfirmSignupCodeAction => ( {
  type: LoginStage.ENTER_OTP,
  email,
  smsCode,
} );

export const loginSuccess = ( result: any ): LoginSuccessAction => ( {
  type: LoginStage.LOGIN_IN_SUCCESS,
  result,
} );

export const userSignUpSuccess = ( user: any ): SignUpSuccessAction => ( {
  type: LoginStage.ENTER_CREDENTIALS_SUCCESS,
  user,
} );

export const verifyOtpSuccess = ( user: any ): VerifyOtpSuccess => ( {
  type: LoginStage.VERIFY_OTP_SUCCESS,
  user,
} );

export const verifyOtpError = ( error: Error ): VerifyOtpError => ( {
  type: LoginStage.VERIFY_OTP_ERROR,
  error,
} );

export const loginError = ( error: Error ): LoginInErrorAction => ( {
  type: LoginStage.LOGIN_IN_ERROR,
  error,
} );

export const userSignUpFailure = ( error: Error ): SignUpErrorAction => ( {
  type: LoginStage.ENTER_CREDENTIALS_ERROR,
  error,
} );

export const setEmailAddress = ( email: string ): SetEmailAddressAction => ( {
  type: EnterCredentials.LOGIN_SET_EMAIL,
  email,
  payload: email,
} );

export const setPassword = ( password: string ): SetPasswordAction => ( {
  type: EnterCredentials.LOGIN_SET_PASSWORD,
  password,
  payload: password,
} );

export const setNewPassword = ( newPassword: string ): SetNewPasswordAction => ( {
  type: EnterCredentials.LOGIN_SET_NEW_PASSWORD,
  newPassword,
  payload: newPassword,
} );

export const signUp = ( email: string, password: string ): EnterCredentialsAction => ( {
  type: LoginStage.ENTER_CREDENTIALS,
  email,
  password,
} );

export const setOtpInput = ( smsCode: string ): SetOtpInputAction => ( {
  type: EnterCredentials.LOGIN_SET_OTP,
  smsCode,
  payload: smsCode,
} );

export const openModal = (): openModal => ( {
  type: ModalAction.OPEN_MODAL,
} );

export const closeModal = (): CloseModal => ( {
  type: ModalAction.CLOSE_MODAL,
} );

export const setStage = ( stage: StageAction ): SetStageAction => ( {
  type: StageAction.SET_STAGE,
  payload: stage,
} );

export type AuthActions =
  | openModal
  | CloseModal
  | SetStageAction
  | SetEmailAddressAction
  | SetPasswordAction
  | EnterCredentialsAction
  | SignUpSuccessAction
  | ConfirmSignupCodeAction
  | SetOtpInputAction
  | LoginAction
  | LoginSuccessAction
  | LoginInErrorAction
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction
  | ResetPasswordRequestAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailureAction
  | VerifyOtpSuccess
  | SignUpErrorAction
  | VerifyOtpError
  | NavigationNext
  | NavigationBack
  | SetNewPasswordAction | InitialRequestAction | InitialSuccessAction | InitialErrorAction | SetLatAction | SetLonAction