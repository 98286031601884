import update from 'immutability-helper';
import { Device } from '../../types';
import { SearchDeviceActionTypes, SearchDeviceActions } from './action';

export interface ListDeviceState {
    loadingSearch: boolean;
    error: null | Error;
    devices: Device[];
    total: number;
    isOpen: boolean;
    isMarkerPlaced: boolean;
    selectedDevice: Device | null;
}

export const initialListDeviceStatus: ListDeviceState = {
    loadingSearch: false,
    error: null,
    devices: [],
    total: 0,
    isOpen: false,
    isMarkerPlaced: false,
    selectedDevice: null,
};

export default function searchDeviceReducer(state = initialListDeviceStatus, action: SearchDeviceActions) {
    switch (action.type) {
        case SearchDeviceActionTypes.SEARCH_DEVICES_REQUEST:
            return update(state, {
                loadingSearch: { $set: true },
            });
        case SearchDeviceActionTypes.SEARCH_DEVICES_SUCCESS:
            return update(state, {
                loadingSearch: { $set: false },
                devices: { $set: action.devices },
            });
        case SearchDeviceActionTypes.SEARCH_DEVICES_ERROR:
            return update(state, {
                loadingSearch: { $set: false },
                error: { $set: action.error },
            });
        case SearchDeviceActionTypes.ZOOM_TO_DEVICE:
            return update(state, {
                selectedDevice: { $set: action.payload },
            });
        default:
            return state;
    }
}
