export enum GetUserActionTypes {
    GET_USER_REQUEST = 'GET_USER_REQUEST',
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',
    GET_USER_ERROR = 'GET_USER_ERROR',
}

export interface GetUserRequestAction {
    type: GetUserActionTypes.GET_USER_REQUEST;
    id: string;
}
export interface GetUserSuccessAction {
    type: GetUserActionTypes.GET_USER_SUCCESS;
    payload: any;
}

export interface GetUserErrorAction {
    type: GetUserActionTypes.GET_USER_ERROR;
    error: Error;
}

export type GetUserActions = GetUserRequestAction | GetUserSuccessAction | GetUserErrorAction;

export function getUserRequest( id: string ): GetUserRequestAction {
    return {
        type: GetUserActionTypes.GET_USER_REQUEST,
        id,
    };
}

export function getUserSuccess( payload: any ): GetUserSuccessAction {
    return {
        type: GetUserActionTypes.GET_USER_SUCCESS,
        payload,
    };
}

export function getUserError( error: Error ): GetUserErrorAction {
    return {
        type: GetUserActionTypes.GET_USER_ERROR,
        error,
    };
}