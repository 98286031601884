/* External dependencies */
import { Observable, filter, switchMap } from 'rxjs';

/* Local dependencies */
import { getAnonymousClient } from '../../../../client/graphql';
import {
  ListDeviceAction,
  ListDeviceActionTypes,
  ListDeviceRequestAction,
  listDeviceError,
  listDeviceSuccess,
} from './action';
import { listDevicesQuery } from './query';

export function listDeviceEpic(action$: Observable<ListDeviceAction>): Observable<ListDeviceAction> {
  return action$.pipe(
    filter(action => action.type === ListDeviceActionTypes.LIST_DEVICES_REQUEST),
    switchMap(({ filter }: ListDeviceRequestAction) =>
      listDevices(filter).then(listDeviceSuccess).catch(listDeviceError),
    ),
  );
}

export async function listDevices({ filter }: any): Promise<any> {
  const graphQLClient = await getAnonymousClient();

  const {
    data: {
      listDevices: { devices, total },
    },
  } = await graphQLClient.query({
    query: listDevicesQuery,
    variables: {
      input: {
        filter,
      },
    },
  });

  return { total, devices };
}

