export enum ListDeviceActionTypes {
  LIST_DEVICES_REQUEST = 'LIST_DEVICES_REQUEST',
  LIST_DEVICES_SUCCESS = 'LIST_DEVICES_SUCCESS',
  LIST_DEVICES_ERROR = 'LIST_DEVICES_ERROR',
  SET_MARKER_PLACED = 'SET_MARKER_PLACED',
  SEARCH_DEVICES_REQUEST = 'SEARCH_DEVICES_REQUEST',
  SEARCH_DEVICES_SUCCESS = 'SEARCH_DEVICES_SUCCESS',
  SEARCH_DEVICES_ERROR = 'SEARCH_DEVICES_ERROR',
  SET_ACTIVE_MARKER = "SET_ACTIVE_MARKER"
}


export interface ActiveMarkerAction {
  type : ListDeviceActionTypes.SET_ACTIVE_MARKER
  payload: any
}

export interface ListDeviceMarkerPlacedAction {
  type: ListDeviceActionTypes.SET_MARKER_PLACED,
  payload: any,
}

export interface ListDeviceRequestAction {
  type: ListDeviceActionTypes.LIST_DEVICES_REQUEST
  filter: any
}

export interface ListDeviceSuccessAction {
  type: ListDeviceActionTypes.LIST_DEVICES_SUCCESS
  filter: any
}

export interface ListDeviceErrorAction {
  type: ListDeviceActionTypes.LIST_DEVICES_ERROR
  error: Error
}


export type ListDeviceAction =
  | ListDeviceRequestAction
  | ListDeviceSuccessAction
  | ListDeviceErrorAction
  | ListDeviceMarkerPlacedAction
  | ActiveMarkerAction

export function listDeviceRequest(filter: any): ListDeviceRequestAction {
  return {
    type: ListDeviceActionTypes.LIST_DEVICES_REQUEST,
    filter
  }
}

export function listDeviceError(error: Error): ListDeviceErrorAction {
  return {
    type: ListDeviceActionTypes.LIST_DEVICES_ERROR,
    error,
  }
}

export function listDeviceSuccess(filter: any): ListDeviceSuccessAction {
  return {
    type: ListDeviceActionTypes.LIST_DEVICES_SUCCESS,
    filter,
  }
}

export const setMarkerPlaced = (isMarkerPlaced: any): ListDeviceMarkerPlacedAction => ({
  type: ListDeviceActionTypes.SET_MARKER_PLACED,
  payload: isMarkerPlaced,
});

export const setActiveMarker = (payload: any): ActiveMarkerAction => ({
  type: ListDeviceActionTypes.SET_ACTIVE_MARKER,
  payload
});
