import { Observable } from "redux";
import { filter, switchMap } from "rxjs/operators";
import { setAuthenticatedClient } from "../../../../client/graphql";
import {
    UpdateDeviceActionType,
    UpdateDeviceActions,
    UpdateDeviceRequestAction,
    updateDeviceError,
    updateDeviceSuccess,
} from "./action";
import { updateDeviceMutation } from "./query";

export function updateDeviceEpic(action$): Observable<UpdateDeviceActions> {
    return action$.pipe(
        filter((action: UpdateDeviceActions) => action.type === UpdateDeviceActionType.UPDATE_DEVICE_REQUEST),
        switchMap(({ updatedFields }: UpdateDeviceRequestAction) =>
            updateDevice(
                updatedFields
            ).then(updateDeviceSuccess).catch(updateDeviceError),
        ),
    );
}


export async function updateDevice(
    updatedFields: any
) {
    const graphQLClient = await setAuthenticatedClient();

    const { data: { updateDevice: device } } = await graphQLClient.mutate({
        mutation: updateDeviceMutation,
        variables: { input:  updatedFields  },
    });

    return device;
}
