import { Observable } from "redux";
import { filter, switchMap } from "rxjs";
import { UpdateUserActionType, UpdateUserActions, UpdateUserRequestAction, updateUsersError, updateUsersSuccess } from "./action";
import { setAuthenticatedClient } from "../../../../client/graphql";
import { updateUserMutation } from "./query";

export function updateUserEpic( action$ ): Observable<UpdateUserActions> {
    return action$.pipe(
        filter( ( action: UpdateUserActions ) => action.type === UpdateUserActionType.UPDATE_USER_REQUEST ),
        switchMap( ( { id, input }: UpdateUserRequestAction ) =>
            updateUser( id, input ).then( updateUsersSuccess ).catch( updateUsersError ),
        ),
    );
}

export async function updateUser( id: string, input: { username: string, userType?: string, avatar: string, phoneNumber: string } ) {
    const graphQLClient = await setAuthenticatedClient();

    const { data: { updateUser: user } } = await graphQLClient.mutate( {
        mutation: updateUserMutation,
        variables: { input: { id, ...input } },
    } );

    return user;
}
