import { Observable, catchError, filter, from, mergeMap, of, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { getUserError, getUserSuccess } from '../../../users/getUser/redux/action';
import { getUserQuery } from '../../../users/getUser/redux/query';
import {
  CreateDeviceAction,
  CreateDeviceActionTypes,
  CreateDeviceRequestAction,
  createDeviceError,
  createDeviceSuccess,
} from './action';
import { createDeviceMutation } from './query';

export function createDeviceEpic(action$): Observable<CreateDeviceAction> {
  return action$.pipe(
    filter((action: CreateDeviceAction) => action.type === CreateDeviceActionTypes.CREATE_DEVICES_REQUEST),
    switchMap(({ input }: CreateDeviceRequestAction) =>
      from(createDevice(input)).pipe(
        mergeMap((device) => {
          const userId = device.user.id;
          return from(getUser(userId)).pipe(
            mergeMap((user) => of(
              createDeviceSuccess(),
              getUserSuccess(user)
            )),
            catchError((error) => of(
              createDeviceSuccess(),
              getUserError(error)
            ))
          );
        }),
        catchError((error) => of(createDeviceError(error)))
      )
    )
  );
}

export async function createDevice(input) {
  const graphQLClient = await setAuthenticatedClient();

  const { data: { createDevice: device } } = await graphQLClient.mutate({
    mutation: createDeviceMutation,
    variables: { input },
  });

  return device;
}

export async function getUser(id) {
  const graphQLClient = await setAuthenticatedClient();

  const { data: { getUser: user } } = await graphQLClient.query({
    query: getUserQuery,
    variables: { input: { id } },
  });

  return user;
}
