// External dependencies
import { gql } from "@apollo/client";

export const deleteDeviceMutation = gql`
mutation DeleteDevice($input: DeleteDeviceInput!){
  deleteDevice(input: $input)  {
    statusMessage
  }
}
`;
