export enum UpdateUserActionType {
    UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
    OPEN_MODAL_UPDATE_USER = 'OPEN_MODAL_UPDATE_USER',
    CLOSE_MODAL_UPDATE_USER = 'CLOSE_MODAL_UPDATE_USER',
    SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
    HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
}

export interface UpdateUserRequestAction {
    type: UpdateUserActionType.UPDATE_USER_REQUEST;
    id: string;
    input: {
        username: string;
        userType?: string;
        avatar: string;
        phoneNumber: string;
    };
}

export interface ShowNotificationAction {
    type: UpdateUserActionType.SHOW_NOTIFICATION;
}

export interface HideNotificationAction {
    type: UpdateUserActionType.HIDE_NOTIFICATION;
}

export interface UpdateUserSuccessAction {
    type: UpdateUserActionType.UPDATE_USER_SUCCESS;
}

export interface UpdateUserErrorAction {
    type: UpdateUserActionType.UPDATE_USER_ERROR;
    error: Error;
}

export interface OpenModalUpdateUserAction {
    type: UpdateUserActionType.OPEN_MODAL_UPDATE_USER;
}

export interface CloseModalUpdateUserAction {
    type: UpdateUserActionType.CLOSE_MODAL_UPDATE_USER;
}

export type UpdateUserActions =
    | UpdateUserRequestAction
    | UpdateUserSuccessAction
    | UpdateUserErrorAction
    | OpenModalUpdateUserAction
    | CloseModalUpdateUserAction
    | ShowNotificationAction
    | HideNotificationAction;

export function updateUsersRequest( id: string, input: { username: string; userType?: string; avatar: string; phoneNumber: string } ): UpdateUserRequestAction {
    return {
        type: UpdateUserActionType.UPDATE_USER_REQUEST,
        id,
        input
    };
}

export function updateUsersSuccess(): UpdateUserSuccessAction {
    return {
        type: UpdateUserActionType.UPDATE_USER_SUCCESS
    };
}

export function updateUsersError( error: Error ): UpdateUserErrorAction {
    return {
        type: UpdateUserActionType.UPDATE_USER_ERROR,
        error,
    };
}

export const openModalUpdateUser = (): OpenModalUpdateUserAction => ( {
    type: UpdateUserActionType.OPEN_MODAL_UPDATE_USER,
} );

export const closeModalUpdateUser = (): CloseModalUpdateUserAction => ( {
    type: UpdateUserActionType.CLOSE_MODAL_UPDATE_USER,
} );

export const showNotification = (): ShowNotificationAction => ( {
    type: UpdateUserActionType.SHOW_NOTIFICATION,
} );

export const hideNotification = (): HideNotificationAction => ( {
    type: UpdateUserActionType.HIDE_NOTIFICATION,
} );
