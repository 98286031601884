/* External dependencies */
import update from 'immutability-helper';
import { UpdateDeviceActionType, UpdateDeviceActions } from './action';

export interface UpdateDeviceState {
  loading: boolean;
  error: null | Error;
  openUpdateDeviceModal: boolean;
  updatedFields: unknown;
  updateDeviceSuccess: boolean;
}

export const initialUpdateDeviceStatus: UpdateDeviceState = {
  loading: false,
  error: null,
  openUpdateDeviceModal: false,
  updatedFields: null,
  updateDeviceSuccess: false
};

export default function updateDeviceReducer(state = initialUpdateDeviceStatus, action: UpdateDeviceActions) {
  switch (action.type) {
    case UpdateDeviceActionType.UPDATE_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true }
      })
    case UpdateDeviceActionType.UPDATE_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        updatedFields: { $set: action.updatedFields },
        updateDeviceSuccess: { $set: true }
      })

    case UpdateDeviceActionType.UPDATE_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error }
      })
    case UpdateDeviceActionType.OPEN_UPDATE_DEVICE:
      return update(state, {
        openUpdateDeviceModal: { $set: true }
      })
    case UpdateDeviceActionType.CLOSE_UPDATE_DEVICE:
      return update(state, {
        openUpdateDeviceModal: { $set: false }
      })
    default:
      return state
  }
} 