/* External dependencies */
import update from 'immutability-helper';
import { UpdateUserActionType, UpdateUserActions } from './action';

export interface UpdateUserState {
    loading: boolean;
    error: null | Error;
    usersData: any;
    openModal: boolean;
    showNotification: boolean;
    success: boolean
}

export const initialUpdateDeviceStatus: UpdateUserState = {
    loading: false,
    error: null,
    usersData: null,
    openModal: false,
    showNotification: false,
    success: false
};

export default function updateUsersReducer(state = initialUpdateDeviceStatus, action: UpdateUserActions) {
    switch (action.type) {
        case UpdateUserActionType.UPDATE_USER_REQUEST:
            return update(state, {
                loading: { $set: true },
            });
        case UpdateUserActionType.UPDATE_USER_SUCCESS:
            return update(state, {
                loading: { $set: false },
                openModal: { $set: false },
                success: { $set: true }
            });
        case UpdateUserActionType.UPDATE_USER_ERROR:
            return update(state, {
                loading: { $set: false },
                error: { $set: action.error },
            });
        case UpdateUserActionType.OPEN_MODAL_UPDATE_USER:
            return update(state, {
                openModal: { $set: true },
            });
        case UpdateUserActionType.CLOSE_MODAL_UPDATE_USER:
            return update(state, {
                openModal: { $set: false },
            });
        case UpdateUserActionType.SHOW_NOTIFICATION:
            return update(state, {
                showNotification: { $set: true },
            });
        case UpdateUserActionType.HIDE_NOTIFICATION:
            return update(state, {
                showNotification: { $set: false },
            });
        default:
            return state;
    }
}
