export enum UpdateDeviceActionType {
  UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST',
  UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS',
  UPDATE_DEVICE_ERROR = 'UPDATE_DEVICE_ERROR',
  OPEN_UPDATE_DEVICE = 'OPEN_UPDATE_DEVICE',
  CLOSE_UPDATE_DEVICE = 'CLOSE_UPDATE_DEVICE',
}

export interface UpdateDeviceRequestAction {
  type: UpdateDeviceActionType.UPDATE_DEVICE_REQUEST
  updatedFields: any
}

export interface UpdateDeviceSuccessAction {
  type: UpdateDeviceActionType.UPDATE_DEVICE_SUCCESS,
  updatedFields: any
}

export interface UpdateDeviceErrorAction {
  type: UpdateDeviceActionType.UPDATE_DEVICE_ERROR
  error: Error
}

export interface OpenUpdateDeviceModalAction {
  type: UpdateDeviceActionType.OPEN_UPDATE_DEVICE
}

export interface CloseUpdateDeviceModalAction {
  type: UpdateDeviceActionType.CLOSE_UPDATE_DEVICE
}

export type UpdateDeviceActions = UpdateDeviceRequestAction | UpdateDeviceSuccessAction | UpdateDeviceErrorAction | OpenUpdateDeviceModalAction | CloseUpdateDeviceModalAction

export function updateDeviceRequest(updatedFields: any): UpdateDeviceRequestAction {
  return {
    type: UpdateDeviceActionType.UPDATE_DEVICE_REQUEST,
    updatedFields,
  };
}


export function updateDeviceSuccess(updatedFields: any): UpdateDeviceSuccessAction {
  return {
    type: UpdateDeviceActionType.UPDATE_DEVICE_SUCCESS,
    updatedFields
  }
}

export function updateDeviceError(error: Error): UpdateDeviceErrorAction {
  return {
    type: UpdateDeviceActionType.UPDATE_DEVICE_ERROR,
    error
  }

}

export function openUpdateDeviceModal(): OpenUpdateDeviceModalAction {
  return {
    type: UpdateDeviceActionType.OPEN_UPDATE_DEVICE
  }
}

export function closeUpdateDeviceModal(): CloseUpdateDeviceModalAction {
  return {
    type: UpdateDeviceActionType.CLOSE_UPDATE_DEVICE
  }
}